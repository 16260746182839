<script setup>
import { ref, provide } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import AniOlmo from "../components/IntroAnimations/AniOlmo.vue";
import IsOlmo from "../components/IsOlmo.vue";
import Videos from "../components/Videos.vue";
import WhatIsOlmo from "../components/WhatIsOlmo.vue";
import Highlights from "../components/Highlights.vue";
import Contact from "../components/Contact.vue";

const tl = gsap.timeline({
  autoRemoveChildren: true,
  defaults: { autoAlpha: 0 },
  onComplete: function () {
    this.kill();
    sessionStorage.setItem("aniOlmo", null);
    ScrollTrigger.refresh();
  },
});

const items = ref([
  {
    text: "navOlmo.whatis",
    href: "#what-is-olmo",
  },
  {
    text: "navOlmo.videos",
    href: "#videos",
  },
  {
    text: "navOlmo.highlights",
    href: "#highlights",
  },
  {
    text: "navOlmo.contact",
    href: "#contact",
  },
  {
    text: "navOlmo.bigPear",
    href: "/",
  },
]);

provide("navbar.items", items);
</script>

<template>
  <div class="olmo-landing">
    <ani-olmo :timeline="tl" />
    <is-olmo :timeline="tl" />
    <what-is-olmo />
    <videos />
    <highlights />
    <contact />
  </div>
</template>

<style lang="scss">
.olmo-landing .go-to-olmo {
  display: none;
}
</style>
