import { createRouter, createWebHistory } from "vue-router";
import BigPear from "../views/BigPear.vue";
import Olmo from "../views/Olmo.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: BigPear,
    },
    {
      path: "/olmo",
      name: "olmo",
      component: Olmo,
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    return { top: 0 };
  },
});

export default router;
