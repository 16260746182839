<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const displayAni = JSON.parse(sessionStorage.getItem("aniBP"));

const onBeforeEnter = (el) => {
  if (displayAni) {
    gsap.from(el, {
      opacity: 0,
      scale: 0,
      transformOrigin: "center",
      delay: 5.5,
      duration: 1,
    });
  }
};

const onAfterEnter = (el) => {
  gsap.to(el.children[1], {
    fill: "white",
    transformOrigin: "center",
    scrollTrigger: {
      trigger: "#about-us",
      endTrigger: "#cases",
      start: "15% center",
      end: "bottom center",
      toggleActions: "play reset resume reset",
      onToggle: (self) =>
        self.isActive
          ? el.parentElement.classList.add("small")
          : el.parentElement.classList.remove("small"),
    },
  });
};
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="147"
    height="473.769"
    viewBox="0 0 147 473.769"
    class="pear"
    aria-hidden="true"
  >
    <Transition
      appear
      @before-enter="onBeforeEnter"
      @after-enter="onAfterEnter"
    >
      <g transform="translate(-1007.529 -526.084)">
        <path
          key="0"
          d="M1115.337,697.38c-18.231-18.807-25.961-44.745-23.748-70.845a78.544,78.544,0,0,0-.417-16.985c-4.545-34.292-32.666-50.3-67.049-53.473a86.342,86.342,0,0,0-16.595-.1V933.089A139.134,139.134,0,0,0,1115.337,697.38Z"
          transform="translate(0 66.554)"
          fill="#c3fa78"
        />
        <path
          key="1"
          d="M1055.076,541.263a15.074,15.074,0,0,0-20.479-14.177l.013-.072c-.172.072-.377.163-.576.247l-.094.039c-5,2.163-16.751,8.169-22.88,21.094-7.834,16.523-.488,34.39,4.786,43.834a1.758,1.758,0,0,0,3.286-.96c-.465-7.619.283-19.249,7.6-27.148,4.841-5.228,11.311-7.1,15.68-7.883l-.039-.042A15.15,15.15,0,0,0,1055.076,541.263Z"
          transform="translate(0.243 0)"
          fill="#e6555a"
        />
      </g>
    </Transition>
  </svg>
</template>
<style lang="scss" scoped>
.pear {
  z-index: 9;
  &.small {
    scale: 0.8 !important;
  }
  transition: 0.8s;
}
</style>
