<script setup>
import state from "../store";
import Hero from "../layouts/Hero.vue";
import data from "../store/projects.json";

const projects = data.projects.filter((x) => x.video);

const showModal = (slug) => {
  state.currentCase = projects.find((p) => p.slug == slug);
  state.showModal = true;
};
</script>

<template>
  <hero id="videos" class="videos is-max-widescreen">
    <template #hero-body>
      <h3 class="title">{{ $t("videos.title") }}</h3>
      <div class="columns">
        <div
          v-for="project in projects"
          :class="project.slug"
          :key="project.slug"
          class="column is-centered"
        >
          <div class="card" @click="showModal(project.slug)">
            <div class="card-header">
              <span class="tag is-danger">{{ $t(project.video.title) }}</span>
            </div>
            <div class="card-content">
              <picture class="image logos-img">
                <img
                  :src="project.video.thumb"
                  :alt="$t(project.video.title)"
                />
              </picture>
            </div>
            <footer class="card-footer">
              <div class="content">
                <span class="icon is-large">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="24"
                      fill="black"
                      fill-opacity="0.5"
                    />
                    <circle
                      cx="24"
                      cy="24"
                      r="23.375"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="1.25"
                    />
                    <path
                      d="M18 15.3589C18 15.0786 18.3592 14.9068 18.6383 15.0537L33.8005 23.0295C34.0555 23.1636 34.0685 23.4767 33.8253 23.6259L18.6631 32.9322C18.3859 33.1023 18 32.9328 18 32.641L18 15.3589Z"
                      fill="white"
                    />
                    <path
                      d="M34.0333 22.587L18.8711 14.6111C18.5808 14.4585 18.2544 14.4728 17.9988 14.5951C17.7436 14.7171 17.5 14.9826 17.5 15.3589L17.5 32.641C17.5 33.0328 17.7617 33.3013 18.0266 33.4176C18.2922 33.5343 18.6325 33.5376 18.9246 33.3583L34.0868 24.0521C34.3524 23.8891 34.512 23.6081 34.4993 23.3013C34.4866 22.9957 34.3059 22.7304 34.0333 22.587Z"
                      stroke="white"
                      stroke-opacity="0.3"
                    />
                  </svg>
                </span>
                <h4 class="title">
                  {{ $t(project.video.authority) }}
                </h4>
                <p class="subtitle is-6">
                  {{ $t(project.video.description) }}
                </p>
                <picture class="image logos-img">
                  <img
                    class="is-hidden-mobile"
                    :src="project.logo"
                    :alt="$t(project.video.title)"
                  />
                </picture>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </template>
  </hero>
</template>
