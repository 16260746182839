<script setup>
import Hero from "../layouts/Hero.vue";
import NavbarMenu from "./NavBarMenu.vue";
import { Timeline } from "gsap/gsap-core";

const tl = defineProps({ timeline: Timeline });

const displayAni = JSON.parse(sessionStorage.getItem("aniOlmo"));

const onBeforeEnterLeaf = (el) => {
  tl.timeline.from(
    el,
    {
      ease: "bounce.out",
      duration: 1.8,
      rotation: -10,
      transformOrigin: "bottom",
    },
    "<"
  );
};

const onBeforeEnterTitle = (el) => {
  tl.timeline.from(el, { y: 50, transformOrigin: "left" }, "<0.5");
};

const onBeforeEnterDesc = (el) => {
  tl.timeline.from(el, { duration: 1 }, "<");
};

const onBeforeEnterSep = (el) => {
  tl.timeline.from(
    el,
    { duration: 1, scaleY: 0, transformOrigin: "center" },
    "<"
  );
};
</script>

<template>
  <Hero id="is-olmo" class="is-olmo">
    <template #hero-head>
      <navbar-menu />
    </template>
    <template #hero-body>
      <article class="media">
        <figure class="media-left">
          <picture class="image">
            <Transition :appear="displayAni" @before-enter="onBeforeEnterLeaf">
              <img class="leaf" aria-hidden="true" src="/Leaf-black.png" />
            </Transition>
          </picture>
        </figure>
        <div class="media-content">
          <div class="content">
            <div class="columns is-vcentered">
              <div class="column">
                <TransitionGroup
                  :appear="displayAni"
                  @before-enter="onBeforeEnterTitle"
                >
                  <h1 key="title" class="title">{{ $t("isOlmo.title") }}</h1>
                  <p key="subtitle" class="subtitle">
                    {{ $t("isOlmo.subtitle") }}
                  </p>
                </TransitionGroup>
              </div>
              <Transition :appear="displayAni" @before-enter="onBeforeEnterSep">
                <span class="separator" aria-hidden="true"></span>
              </Transition>
              <div class="column content">
                <Transition
                  :appear="displayAni"
                  @before-enter="onBeforeEnterDesc"
                >
                  <p class="description">{{ $t("isOlmo.description") }}</p>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </Hero>
</template>
